import React from "react";
import firebase from '../lib/firebase';
import { toast } from "react-toastify";

export default function GenerateICS({currentStep, setCurrentStep, assocData, leaguesData}: {currentStep: string, setCurrentStep: Function, assocData: object, leaguesData: object}) {

    const [selectedClubId, setSelectedClubId] = React.useState('');
    const [selectedClubName, setSelectedClubName] = React.useState('');
    const [selectedGroundId, setSelectedGroundId] = React.useState('');
    const [selectedGroundName, setSelectedGroundName] = React.useState('');
    const [selectedLeagueId, setSelectedLeagueId] = React.useState('');
    const [selectedLeagueName, setSelectedLeagueName] = React.useState('');
    const [newICSLink, setNewICSLink] = React.useState(''); 

    const createICSLink = () => {
        fetch('/api/generateICSLink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                selectedClubId,
                selectedClubName,
                selectedGroundId,
                selectedGroundName,
                selectedLeagueId,
                selectedLeagueName,
                userUID: firebase.auth().currentUser?.uid,
                // @ts-ignore
                assocId: assocData.id
            })
        }).then((res) => res.json()).then((res) => {
            setNewICSLink(res.newICSLink);
        })
    }
    

    return (
        <div>
            <button style={{position: 'absolute', top: '10px', left: '110px'}} className='bg-slate-200 px-2 rounded-md' onClick={() => {setCurrentStep('selectGround');}}>Back</button>
            <h1 style={{fontWeight: 700, fontSize: '25px'}}>Create a calendar link</h1>
            <p>Here you can generate a calendar link for your team&apos;s fixtures</p>
            <p>Select which filters you would like to use for this link</p>
            <p style={{fontWeight: '700'}}>Ground</p>
            <select className='rounded-md px-2' id='selectGround'  title='Select a ground' value={selectedGroundId} onChange={(e) => {setSelectedGroundId(e.target.value); setSelectedGroundName(e.target.selectedOptions[0].innerText)}}>
                <option disabled>Select a ground...</option>
                <option value={undefined}>All</option>
                {/* @ts-ignore */}
                {Object.values(assocData).length > 0 ? assocData.grounds.map((ground, index) => (
                  <option value={ground.hash_id} key={index}>{ground.name}</option>
                  )): null}
            </select>
            <br />
            <br />
            <p style={{fontWeight: '700'}}>League / Division</p>
            <select className='rounded-md px-2' id='selectClub'  title='Select a league/division' value={selectedLeagueId} onChange={(e) => {setSelectedLeagueId(e.target.value); setSelectedLeagueName(e.target.selectedOptions[0].innerText)}}>
                <option disabled>Select a league/division...</option>
                <option value={undefined}>All</option>
                {/* @ts-ignore */}
                {Object.values(leaguesData).length > 0 ? leaguesData.data.map((league, index) => (
                  <option value={league.id} key={index}>{league.name}</option>
                  )): null}
            </select>
            <br />
            <br />
            <button className='bg-slate-200 px-2 rounded-md' onClick={() => createICSLink()}>Generate Link</button>
            <p style={{fontWeight: '700'}}>Your link is:</p>
            <p style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {navigator.clipboard.writeText(newICSLink); toast.success(`URL copied to clipboard`, {autoClose: 1000})}}>{newICSLink}</p>
            <br />
            <p style={{fontWeight: '700'}}>Add this link to Google Calendar / Apple Calendar / Outlook to have fixtures synced as they are published.</p>
        </div>
    )
}